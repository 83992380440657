import { Panel, PanelHeader, PanelHeaderButton, Alert } from '@vkontakte/vkui';
import { Icon28ChevronBack } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { Icon20VolumeOutline } from '@vkontakte/icons';
import { Icon24Broadcast } from '@vkontakte/icons';
import { Icon20FavoriteCircleFillYellow } from '@vkontakte/icons';
import { Icon20ServicesCircleFillBlue } from '@vkontakte/icons';
import { Icon20ReplyCircleFillGreen } from '@vkontakte/icons';
import Switch from '@mui/material/Switch';
import request from '../hooks/useHttp'
import { useEffect } from 'react';


const Settings = ({id, go, user, setUser, setPopout}) => {


const setAlert = (text) => {
    setPopout(
      <Alert
        actionsLayout="vertical"
        actions={[
          {
            title: 'Ок',
            autoclose: true,
            mode: 'cancel',
          },
        ]}
        onClose={() => {
          setPopout(null)
        }}
      >
        <p>{text}</p>
      </Alert>
    )
  }

    const setSettings = async (id, status) => {
        const data = await request('set_settings', 'POST', {
            url: document.location.search,
            id,
            status
        })

        if (!data.error) {
            setUser(data.user)
        }
    }

    useEffect(() => {
        console.log('ok');
    }, [user])

    return (
        <Panel id={id}>
        <PanelHeader 
            left={
                <PanelHeaderButton
                    onClick={() => {
                      if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                      }
                        go({currentTarget: {dataset: {to: 'home'}}})
                    }}>
    
                    <Icon28ChevronBack fill="white" />
                    </PanelHeaderButton>
                }>
            Настройки
        </PanelHeader>
        <div className="statistic-menu">
                <div className="statistic-menu-header">Настройки</div>
                <div className="settings-menu-item">
                    <Icon20VolumeOutline width={30} height={30}/>
                    <div className="statistic-menu-item-state">Звук</div>
                    <Switch defaultChecked={user.sound} onChange={() => {
                      if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                    }
                        setSettings('sound', !user.sound)
                    }}/>
                </div>

                <div className="settings-menu-item">
                    <Icon24Broadcast width={30} height={30}/>
                    <div className="statistic-menu-item-state">Вибрация</div>
                    <Switch defaultChecked={user.vibration} onChange={() => {
                      if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                    }
                        setSettings('vibration', !user.vibration)
                    }}/>
                </div>
        </div>

        <div className="statistic-menu">
                <div className="statistic-menu-header">Дополнительно</div>
                <div className="statistic-menu-item" onClick={async () => {
                    try {
                      await bridge.send("VKWebAppAddToFavorites");
                      if (user.vibration) {
                        bridge.send("VKWebAppTapticNotificationOccurred", {"type": "success"});
                      }
                      setAlert('Мини-приложение добавлено в избранные!')
                    } catch (e) {
                      if (user.vibration) {
                        bridge.send("VKWebAppTapticNotificationOccurred", {"type": "error"});
                      }
                      setAlert('Произошла неизвестная ошибка!')
                    }
                      }}>
                    <Icon20FavoriteCircleFillYellow width={30} height={30}/>
                    <div className="statistic-menu-item-state">В избранное</div>
                </div>

                <div className="statistic-menu-item" onClick={async () => {
                    try {
                      let short = await bridge.send("VKWebAppAddToHomeScreenInfo");
                      let text = ''
                      let error = false
                      if (short.is_added_to_home_screen) {
                        text = 'Вы уже добавили приложение на главный экран устройства'
                        error = true
                      }
                      if (!short.is_feature_supported) {
                        text = 'Недоступно на вашем устройстве'
                        error = true
                      }
                      if (short.is_feature_supported && !short.is_added_to_home_screen) {
                        try {
                          await bridge.send("VKWebAppAddToHomeScreen");
                        } catch (e) {
                          text = 'Ошибка!'
                          error = true
                        }
                      }

                      if (error) {
                        if (user.vibration) {
                          bridge.send("VKWebAppTapticNotificationOccurred", {"type": "error"});
                      }
                      } else {
                        if (user.vibration) {
                          bridge.send("VKWebAppTapticNotificationOccurred", {"type": "success"});
                      }
                      }
                      setAlert(text)
                    } catch (e) {
                      if (user.vibration) {
                        bridge.send("VKWebAppTapticNotificationOccurred", {"type": "error"});
                    }
                      setAlert('Произошла неизвестная ошибка!')
                    }}}>
                    <Icon20ServicesCircleFillBlue width={30} height={30}/>
                    <div className="statistic-menu-item-state">На главный экран</div>
                </div>

                <div className="statistic-menu-item" onClick={() => {
                  if (user.vibration) {
                    bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                  }
                     bridge.send("VKWebAppShare", {"link": "vk.com/app8175004"});
                }}>
                    <Icon20ReplyCircleFillGreen width={30} height={30}/>
                    <div className="statistic-menu-item-state">Поделиться</div>
                </div>
        </div>
        </Panel>
    )
}

export default Settings