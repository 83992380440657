import React, { useState, useEffect } from 'react';
import { Avatar, Panel, PanelHeader, PanelHeaderButton,Spinner } from '@vkontakte/vkui';
import { Icon28ChevronBack } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import request from '../hooks/useHttp'
import { Icon20ReplyCircleFillGreen } from '@vkontakte/icons';

const Settings = ({id, go, user, Class}) => {
    let [body, setBody] = useState(<Spinner />)

    useEffect(() => {

        const start = async () => {
            const token = await bridge.send("VKWebAppGetAuthToken", {"app_id": 8175004, "scope": "friends"});
    
            const friends = await bridge.sendPromise('VKWebAppCallAPIMethod', {
                method: 'friends.get',
                request_id: '324nnefj',
        
                params: {
                  v: '5.95',
                  fields: 'photo_200',
                  access_token: token.access_token,
                },
              })

              console.log(friends);
            const data = await request('get_friends', 'POST', {
				url: document.location.search,
                friends: friends.response.items
			})

            if (data.friends.length < 1) {
                setBody(<div>
                    <div className='top-info-header'>Никто из списка ваших друзей не играет в Football Quiz </div>

                    <div className="statistic-menu-item" onClick={() => {
                        if (user.vibration) {
                          bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                        }
                        bridge.send("VKWebAppShowInviteBox", {}).then(data => console.log(data.success)).catch(error => console.log(error));
                        }}>
                    <Icon20ReplyCircleFillGreen width={30} height={30}/>
                    <div className="statistic-menu-item-state">Пригласить</div>
                </div>
                </div>
                
                )
            } else {
                let _body = [<div className='top-info-header'>Результаты ваших друзей:</div>]
                data.friends.sort((a, b) => { return b.progress - a.progress}).map(x => {
                    let user = friends.response.items.find(s => s.id === x.id)
                    _body.push(
                    <div className='top-info-item'>
                        <Avatar src={user.photo_200} style={{border: '1px solid white'}}/>
                        <div>
                            <div>{user.first_name}</div>
                            <div className='game-menu-item-state'>

                                <div className='game-menu-item-state-bottom'>
                                    <p>Логотипы</p>
                                    <div>{x.progress}</div>
                                </div>

                                <div className='game-menu-item-state-bottom'>
                                    <p>Очки</p>
                                    <div>{x.stars}</div>
                                </div>
                                    
                            </div>
                            
                        </div>
                        <div>{x.lvl}</div>
                    </div>
                    )
                })

                setBody(_body)
            }
        }

        start()

    }, []
    
    )
    return (
        <Panel id={id}>
        <PanelHeader 
            left={
                <PanelHeaderButton
                    onClick={() => {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                        go({currentTarget: {dataset: {to: 'home'}}})
                    }}>
    
                    <Icon28ChevronBack fill="white" />
                    </PanelHeaderButton>
                }>
            Топ
        </PanelHeader>
        <div className={Class}>
        <div className='top-info'>
            {body}
        </div>
        </div>
       
        </Panel>
    )
}

export default Settings