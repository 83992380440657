import React, { useEffect, useState } from "react";
import { Panel, PanelHeader, PanelHeaderButton, Alert, Spinner, Avatar, Snackbar, Button } from '@vkontakte/vkui';
import { Icon28ChevronBack } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import HeaderInfo from './utils/HeaderInfo';
import request from '../hooks/useHttp'
import win from '../img/win.png'
import useSound from 'use-sound';
import './GameItem.css'
import { Icon20ViewCircleFillRed } from '@vkontakte/icons';
import { Icon20TextLiveCircleFillGreen } from '@vkontakte/icons';
import {Icon16ErrorCircleOutline } from '@vkontakte/icons'
import md5 from "md5";

const GameItem = ({ id, user, item, go, string, setUser, setProgress, thisProgress, setThisProgress, setPopout}) => {
    let [words, setWords] = useState([])
    let [key, setKey] = useState([])
    let [keyboard, setKeyboard] = useState(<Spinner />)
    let [word, setWord] = useState(<Spinner />)
    let [img, setImg] = useState(item.img)
    let [error, setError] = useState(null)
    const [playWin] = useSound('https://quiz.footballcoin.ru/win.mp3');
    const [playLose] = useSound('https://quiz.footballcoin.ru/lose.mp3');
    const [snackbar, setSnackbar] = useState(null)

    const delWord = (id) => {
        if (!key.find(x => x.s === '0')) return
        let ky = []
        let start = false 
        key.map(x => {
            if (x.s === '0' && start === false) {
                
                start = true
                let k = words.find(x => x.id === id && x.s != '0')
                ky.push(k)
            } else {
                ky.push(x)
            }
        })

        let w = []
        console.log(words);
        words.map(x => {
            if (x.id === id && x.s != '0' && x.s != '-' && x.s != ' '  ) {
                w.push({id: 1001, s: '0'})
            } else {
                w.push(x)
            }
        })

        setWords(w)
        setKey(ky)
    }

    const newWord = (id) => {
        if (!words.find(x => x.s === '0')) return
        let w = []
        let start = false
        words.map(x => {
            if (x.s === '0' && start === false) {
                start = true
                let k = key.find(x => x.id === id)
                w.push(k)
            } else {
                w.push(x)
            }
        })

        let k = []
        key.map(x => {
            if (x.id === id) {
                k.push({ id: 1001, s: '0'})
            } else {
                k.push(x)
            }
        })
        setWords(w)
        setKey(k)
    }


    const goBack = () => {
        let prog = []
        thisProgress.map(x => {
            prog.push(x)
            if (x.id === item.id) {
                let name = ''
                words.map(x => {name += x.s})
                prog[prog.length - 1].img = img
                prog[prog.length - 1].name = name
            }
            setThisProgress(prog)
        })
    }

    const help = async (type) => {
        const err = (data) => {
            console.log(data);
            setSnackbar(
                <Snackbar
                    onClose={() => setSnackbar(null)}
                    after={data.code === 188 ? <Button onClick={ () => {go({currentTarget: {dataset: {to: 'shop'}}})}}>Получить</Button> : null}
                    before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
                    <Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
                    </Avatar>}
                    >
                    {data.error}
                  </Snackbar>
            )
        }

        if (type === 'full') {
            const data = await request('help', 'POST', {
                url: document.location.search,
                id: item.id,
                type
            })

            if (data.error) {
                err(data)
                return
            }
            setUser(data.user)
        }

        if (type === 'excess') {
            const data = await request('help', 'POST', {
                url: document.location.search,
                id: item.id,
                type
            })

            if (data.error) {
                err(data)
                return
            }

            setUser(data.user)
        }
    }
        

    useEffect(() => {
        if (!user.progress.find(x => x === item.id)) {
            let HELP = user.help.filter(x => x.id === item.id)
            if (HELP.length > 0) {
                HELP.map(x => {
                    if (x.type === 'full') {
                        let name = []
                        for (let i = 0; i < x.result.length; i++) {
                            name.push({id: name.length, s: x.result[i]})
                        }
                        setWords(name)
                    }
                })
            }
        }
    }, [user])


    useEffect(() => {
        const start = async () => {
            let hash = await md5(`${item.id}-${user.id}-CAIUECNA`)
            console.log(hash);
            let name = ''
            words.map(x => {name += x.s})
            const data = await request('get_result', 'POST', {
                url: document.location.search,
                id: item.id,
                hash,
                name: name
            })

            if (!data.error) {
                if (user.vibration) {
                    bridge.send("VKWebAppTapticNotificationOccurred", {"type": "success"});
                }
                
                setUser(data.user)
                setProgress(data.progress)
                setImg(data.img)
                setKey([])
                if (user.sound) {
                    playWin()
                }

                if (data.prizes.length > 0) {
                    setPopout(<Alert
                        actionsLayout="horizontal"
                        actions={[
                          {
                            title: 'Понятно',
                            autoclose: true,
                            mode: 'cancel',
                          },
                        ]}
                        onClose={() => setPopout(null)}
                      >
                        <p>{data.prizes[0].message}</p>
                      </Alert>)
                }

                let rand = Math.round(Math.random() * 5) 
                if (rand === 3 && !user.don) {
                    bridge.send("VKWebAppShowNativeAds", {ad_format:"reward"})
                }
                
            } else {
                setError(true)
                if (user.sound) {
                    playLose()
                }
                if (user.vibration) {
                    bridge.send("VKWebAppTapticNotificationOccurred", {"type": "error"});
                }
                
            }
            return 
        }
        
        if (words.length > 0 && !img && !words.find(x => x.s === '0')) {
            start()
        }
    }, [word])

    useEffect(() => {
        if (user.help.find(x => x.id === item.id && x.type === 'full')) return
        if (user.progress.find(x => x === item.id)) return
        if (string) {
            let w = []
            let s = []

            string.string.map(x => {
                s.push({
                    id: s.length,
                    s: x
                })
            })

            string.words.map(x => {
                w.push({
                    id: w.length,
                    s: x
                })
            })

            setWords(s)
            setKey(w)
        }
    }, [string])

    useEffect(() => {
        if (item.img) {
            let _words = []
            for (let i = 0; i < item.name.length; i++) {
                _words.push(
                    <div key={Math.random() * 1000000} className="game-item-words-button" style={{ backgroundColor: item.name[i] === ' ' 
                    || item.name[i] === '-' ? '#1802dd50' : 'var(--item_content)'}} onClick={() => {
                        if (item.name[i] === '0' || item.name[i] === ' ' || item.name[i] === '-') return
                    }}>
                        {item.name[i] === '0' ? '' : item.name[i]}
                    </div>
                )
            }

            setWord(_words)
            setKeyboard(null)
            return
        }

        let _words = []
        let _key = []
        words.map(x => {
            _words.push(
                <div key={Math.random() * 1000000} className="game-item-words-button" style={{ backgroundColor: x.s === ' ' 
                || x.s === '-' ? '#1802dd50' : 'var(--item_content)'}} onClick={() => {
                    if (x.s === '0' || x.s === ' ' || x.s === '-') return
                    if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                    }
                    delWord(x.id)
                }}>
                    {x.s === '0' ? '' : x.s}
                </div>
            )
        })

        key.map(x => {
            _key.push(
                <div key={Math.random() * 1000000} className="game-item-key-button" onClick={() => {
                    if (x.id === 1001) return
                    if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                    }
                    newWord(x.id)
                }}>
                    {x.s === '0' ? '' : x.s}
                </div>
            )
        })

        setKeyboard(_key)
        setWord(_words)
    }, [words, key])

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 1300)
    }, [error])

    return (
        <Panel id={id}>
            <PanelHeader 
                left={
                    <PanelHeaderButton
                        onClick={() => {
                            goBack()
                            if (user.vibration) {
                                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                            }
                            go({currentTarget: {dataset: {to: 'start'}}})
                        }}>
        
                        <Icon28ChevronBack fill="white" />
                        </PanelHeaderButton>
                    }>
                Игра
            </PanelHeader>
            <HeaderInfo user={user} />

            <div className="game-item-main">
                <div className="game-item-main-help">
                    <div style={{opacity: user.help.find(x => x.id === item.id && x.type === 'excess') ? 0.5 : 1}} onClick={() => {
                        if (user.help.find(x => x.id === item.id && x.type === 'excess')) return
                        if (user.vibration) {
                            bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                        }

                        setPopout(<Alert
                            actionsLayout="horizontal"
                            actions={[
                              {
                                  title: "Да",
                                  mode: "destructive",
                                  autoclose: true,
                                  action: () => { help('excess') }
                                },
                              {
                                title: 'Нет',
                                autoclose: true,
                                mode: 'cancel',
                              },
                            ]}
                            onClose={() => setPopout(null)}
                          >
                            <p>Вы хотите убрать лишние буквы? Будет списано 5 подсказок</p>
                          </Alert>)
                    }}><Icon20ViewCircleFillRed width={30} height={30}/></div>

                    <div onClick={() => {
                        if (user.vibration) {
                            bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                        }

                        setPopout(<Alert
                            actionsLayout="horizontal"
                            actions={[
                              {
                                  title: "Да",
                                  mode: "destructive",
                                  autoclose: true,
                                  action: () => { help('full') }
                                },
                              {
                                title: 'Нет',
                                autoclose: true,
                                mode: 'cancel',
                              },
                            ]}
                            onClose={() => setPopout(null)}
                          >
                            <p>Вы хотите получить все слово целиком? Будет списано 10 подсказок</p>
                          </Alert>)
                    }}><Icon20TextLiveCircleFillGreen width={30} height={30}/></div>
                </div>

            <div className="game-item-image">
                <img src={img ? `https://quiz.footballcoin.ru/quiz/img/${img}.png` :`https://quiz.footballcoin.ru/quiz/img/${item.id}.png`} alt='command' />
            </div>

            <div className="game-item-main-help">
                    
            </div>
            </div>
            
            <div className={error ? "game-item-words-error" : "game-item-words"}>{word}</div>
            <div className="game-item-keyboard">{keyboard}</div>
            {img ? <div className="win-image"><img src={win} alt='Победа'/></div> : null}
            {img ? <div className="win-image-menu"  onClick={() => {
                console.log('ok');
                goBack()
                if (user.vibration) {
                    bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                }
                go({currentTarget: {dataset: {to: 'start'}}})
            }}><i></i><i></i><i></i><i></i><i></i><i></i>
  <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i>
      </i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div> : null}
      {snackbar}
        </Panel>
    )
}

export default GameItem