import React, { useEffect, useState } from "react";
import { Panel, PanelHeader, PanelHeaderButton, Spinner } from '@vkontakte/vkui';
import { Icon28ChevronBack } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import HeaderInfo from './utils/HeaderInfo';
import Rating from '@mui/material/Rating';
import './Start.css'
import request from '../hooks/useHttp'

const Start = ({ id, go, thisProgress, user, setItem, setProgress, setUser, setThisProgress, Class}) => {
    const [body, setBody] = useState(null)

    useEffect(() => {
		async function fetchData() {

			const data = await request('get_user', 'POST', {
				url: document.location.search,
			})

			const games = await request('get_games', 'POST', {
				url: document.location.search,
			})

            let thisProg = games.progress.find(x => x[0].id === thisProgress[0].id)
            setThisProgress(thisProg)
			setProgress(games.progress)
			setUser(data.user)
		}
		fetchData()

        
	}, []);

    useEffect(() => {
        let _body = []
        thisProgress.map(x => {
            _body.push(
                <div className="start-game-menu-item" style={{ backgroundColor: x.img ? 'var(--item-active)' : 'var(--item)'}} onClick={() => {
                    if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                    }
                        setItem(x)
                        go({currentTarget: {dataset: {to: 'game'}}})
                }}>
                    <img src={x.img ?  `https://quiz.footballcoin.ru/quiz/img/${x.img}.png` : 
                    `https://quiz.footballcoin.ru/quiz/img/${x.id}.png`} alt='command' />
                    <Rating
                        size="small"
                        name="simple-controlled"
                        value={x.stars / 2}
                        precision={0.5} 
                        readOnly
                    />
                </div>
            )
        })

        setBody(_body)
    }, [thisProgress, user])


    useEffect(() => {
        console.log('ok');
    }, [body])


    return (
<Panel id={id}>
    <PanelHeader 
        left={
            <PanelHeaderButton
                onClick={() => {
                    if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                    }
	    			go({currentTarget: {dataset: {to: 'games'}}})
                }}>

                <Icon28ChevronBack fill="white" />
                </PanelHeaderButton>
            }>
        Игра
    </PanelHeader>
    <HeaderInfo user={user} />
    <div className={Class}>
        <div className="start-game-menu">{body}</div>
    </div>
</Panel>
    )
}

export default Start