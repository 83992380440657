import React, {useEffect, useState} from 'react'
import { Panel, PanelHeader, PanelHeaderButton, Spinner } from '@vkontakte/vkui';
import { Icon28ChevronBack } from '@vkontakte/icons';
import { Icon56LockOutline } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './Game.css'
import HeaderInfo from './utils/HeaderInfo';

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="white">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

const Games = ({id, progress, go, user, setThisProgress, games, setGames, gameType, Class}) => {

    useEffect(() => {
        let game = []
        let lvl = 1
        let LVL = 0
        progress.map(x => {
            LVL ++
            if (LVL > 40) return
            if (gameType === 'players' && progress.indexOf(x) < 19) return 
            let xp = 0
            let stars = 0
            x.map(s => {
                if (s.img) {
                    stars += s.stars
                    xp++
                }
            })
            if (lvl > user.lvl) {
                game.push(
                    <div className='game-menu-item' onClick={() => {
                        if (user.vibration) {
                            bridge.send("VKWebAppTapticNotificationOccurred", {"type": 'error'});
                        }
                    }}>
                        <Icon56LockOutline style={{ position: 'absolute', left: 'calc(50% - 28px)', top: 'calc(50% - 28px)', color: 'white'}}/>
                        <div className='game-menu-item-header'  style={{opacity: 0.5}}>{`Уровень ${LVL}`}</div>
                        <div className='game-menu-item-state'  style={{opacity: 0.5}}>
                            <div className='game-menu-item-state-bottom'><p>ЛОГОТИПЫ</p><div>{xp}/{x.length}</div></div>
                            <div className='game-menu-item-state-bottom'><p>ОЧКИ</p><div>{stars}</div></div>
                        </div>
                        <LinearProgressWithLabel  style={{opacity: 0.5}} value={xp / x.length * 100} />
                    </div>
                )
            } else {
                game.push(
                    <div className='game-menu-item' onClick={() => {
                        setThisProgress(x)
                        go({currentTarget: {dataset: {to: 'start'}}})
                        if (user.vibration) {
                            bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                        }
                    }}>
                        <div className='game-menu-item-header'>{`Уровень ${LVL}`}</div>
                        <div className='game-menu-item-state'>
                            <div className='game-menu-item-state-bottom'><p>ЛОГОТИПЫ</p><div>{xp}/{x.length}</div></div>
                            <div className='game-menu-item-state-bottom'><p>ОЧКИ</p><div>{stars}</div></div>
                        </div>
                        <LinearProgressWithLabel value={xp / x.length * 100} />
                    </div>
                )
            }
            lvl ++
        })

        setGames(game)
    }, [progress])

    useEffect(() => {
        console.log('ok');
    }, [games])

    return ( 
<Panel id={id}>
    <PanelHeader 
        left={
            <PanelHeaderButton
                onClick={() => {
                    if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                    }
	    			go({currentTarget: {dataset: {to: 'home'}}})
                }}>

                    <Icon28ChevronBack fill="white" />
                    </PanelHeaderButton>
            }>
        Уровни
    </PanelHeader>
    <HeaderInfo user={user} />
    <div className={Class}>
        <div className='game-menu'>{games}</div>
    </div>
</Panel>
)
}

export default Games