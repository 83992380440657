import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';

import { Panel, PanelHeader, Footer, Link } from '@vkontakte/vkui';
import { Icon32PollOutline } from '@vkontakte/icons';
import { Icon28SettingsOutline } from '@vkontakte/icons';
import { Icon20Users3Outline } from '@vkontakte/icons';
import { Icon56LockOutline } from '@vkontakte/icons';
import { Icon28StopwatchOutline } from '@vkontakte/icons';

import './Home.css'
import HeaderInfo from './utils/HeaderInfo';
import bottomItem from '../img/bottom.png'

import request from '../hooks/useHttp'

const Home = ({ id, go, user, setGameType, setUser }) => {
	const [time, setTime] = useState(null)

	function msToTime(duration) {
		let days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 24)
		let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
		let minutes = Math.floor((duration / (1000 * 60)) % 60)
		let secounds = Math.floor((duration / (1000)) % 60)

		return  days > 0 ? days + "д " + hours + "ч " + minutes + "м " + secounds + 'с' :
		hours + "ч " + minutes + "м " + secounds + 'с'
	  }

	useEffect(() => {
		const imterval = setInterval(() => {
			if (1654473600000 - Date.now() - 10800000 + (172800000 * 2) > 0) {
				setTime(<div><Icon28StopwatchOutline />{msToTime(1654473600000 - Date.now() - 10800000 + (172800000 * 2))}</div>)
			} else {
				setTime(null)
			}
		}, 1000)

		return () => {
			clearInterval(imterval)
		}
	}, [])

	return <Panel id={id}>
		<PanelHeader>Football Quiz</PanelHeader>
		<HeaderInfo user={user} />
		<div className='main-menu'>
			<div className='main-menu-button' onClick={() => {
				if (user.vibration) {
					bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
				}
				setGameType('commands')
				go({currentTarget: {dataset: {to: 'games'}}})
			}}>Играть</div>


			{
			user?.lvl > 999 ?
			<div className='main-menu-button' onClick={() => {
				if (user.vibration) {
					bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
				}
				setGameType('players')
				go({currentTarget: {dataset: {to: 'games'}}})
			}}>Дополнительные уровни</div>  
			:
			<div className='main-menu-button' onClick={() => {
				if (user.vibration) {
					bridge.send("VKWebAppTapticNotificationOccurred", {"type": 'error'});
				}
			}}><Icon56LockOutline  width={32} height={32} style={{ position: 'absolute', left: 'calc(50% - 16px)', top: 'calc(50% - 16px)', color: 'white'}}/>
			<div style={{opacity: 0.3}}>Дополнительные уровни</div>
			<div style={{opacity: 0.5, fontSize: 12}}>Пока недоступно</div></div>
			}

			<div className='main-menu-button'  onClick={() => {
				if (user.vibration) {
					bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
				}
				go({currentTarget: {dataset: {to: 'shop'}}})
			}}>
				<div>Получить подсказки</div>
			</div>

			<div className='main-menu-icons'>
				<Icon32PollOutline width={36} height={36} onClick={() => {
				if (user.vibration) {
					bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
				}
				go({currentTarget: {dataset: {to: 'statistic'}}})
			}}/>
				<Icon20Users3Outline width={36} height={36} onClick={() => {
				if (user.vibration) {
					bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
				}
				go({currentTarget: {dataset: {to: 'top'}}})
			}}/>
				<Icon28SettingsOutline width={36} height={36} onClick={() => {
				if (user.vibration) {
					bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
				}
				go({currentTarget: {dataset: {to: 'settings'}}})
			}}/>
			</div>
			<div className='timer'>
			{time}
			</div>
			<Footer>Made by <Link target="_blank" href="https://хор-енотов.рф">ХОР ЕНОТОВ</Link></Footer>
		</div>
		<div className='main-menu-bottom-item'><img src={bottomItem}/></div>

	</Panel>
}



Home.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.number,
		science: PropTypes.number,
		progress: PropTypes.array
	}),
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	fetchedUser: PropTypes.shape({
		photo_200: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		city: PropTypes.shape({
			title: PropTypes.string,
		}),
	}),
};

export default Home;
