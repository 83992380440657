import { Panel, PanelHeader, Snackbar, Avatar, PanelHeaderButton } from '@vkontakte/vkui';
import { Icon28ChevronBack } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { Icon24VideoOutline, Icon16ErrorCircleOutline} from '@vkontakte/icons';
import { Icon24ShoppingCartOutline } from '@vkontakte/icons';
import request from '../hooks/useHttp'
import {  useState } from 'react';
import { Icon16UserAdd } from '@vkontakte/icons';
import { Icon24UserAdded } from '@vkontakte/icons';
import { Icon20CheckCircleFillGreen } from '@vkontakte/icons';
import { Icon28ShareExternal } from '@vkontakte/icons';

const Shop = ({id, go, user, setUser, Class}) => {


    function order(tag, money) {
        const params = {
            type: 'item',
            item: tag
        };
    
        bridge.send('VKWebAppShowOrderBox', params).then(data => console.log(data.status))  
        .catch(error => console.log(error));
    }

	const [snackbar, setSnackbar] = useState(null)

    const getFreeScinse = async () => {
		let promo = await bridge.send("VKWebAppCheckNativeAds", {"ad_format": "reward"});
		if (promo.result === false) {
			setSnackbar(
                <Snackbar
                    onClose={() => setSnackbar(null)}
                    before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
                    <Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
                    </Avatar>}>Для вас нет доступной рекламы</Snackbar>
            )
		} else {
			bridge.send("VKWebAppShowNativeAds", {ad_format:"reward"})
				.then(async data => {
					if (data.result) {
						let req = await request('get_free_science', 'POST', {
							url: document.location.search,
						})
						if (req.error) {
							setSnackbar(
								<Snackbar
									onClose={() => setSnackbar(null)}
									before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
									<Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
									</Avatar>}>{req.error}</Snackbar>
							)
						} else {
							setUser(req.user)
						}
					}
					
				}).catch(error => {
                    console.log(error);
					setSnackbar(
						<Snackbar
							onClose={() => setSnackbar(null)}
							before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
							<Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
							</Avatar>}>{error.message || 'Ошибка!'}</Snackbar>
					)
				});
		}
	}

    const getFreeScinsePost = async () => {
        if (user.post) return setSnackbar(
            <Snackbar
                onClose={() => setSnackbar(null)}
                before={<Avatar size={24} style={{ background: 'green' }}>
                <Icon20CheckCircleFillGreen fill="green" width={24} height={24} />
                </Avatar>}>Вы уже получили подарок!</Snackbar>
        )

        bridge.send("VKWebAppShowWallPostBox", {
            "message": "Угадывай футбольные команды используя свои знания в футболе!",
            "attachments": "https://vk.com/quiz_footballcoin"
          }).then(async data => {
            console.log(data);
            if (data.post_id) {
                let req = await request('get_free_science_post', 'POST', {
                    url: document.location.search,
                })
                if (req.error) {
                    setSnackbar(
                        <Snackbar
                            onClose={() => setSnackbar(null)}
                            before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
                            <Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
                            </Avatar>}>{req.error}</Snackbar>
                    )
                } else {
                    setSnackbar(
                        <Snackbar
                            onClose={() => setSnackbar(null)}
                            before={<Avatar size={24} style={{ background: 'green' }}>
                            <Icon20CheckCircleFillGreen fill="#fff" width={24} height={24} />
                            </Avatar>}>Вы получили 20 подсказок!</Snackbar>
                    )
                    setUser(req.user)
                }
            }
        }).catch(e => {
            console.log(e);
            setSnackbar(
                <Snackbar
                    onClose={() => setSnackbar(null)}
                    before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
                    <Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
                    </Avatar>}>{'Ошибка, вы не поделились записью!'}</Snackbar>
                )
        })
    }


    const getFreeScinseMenu = () => {
        bridge.send("VKWebAppCallAPIMethod", {
            method: 'showSettingsBox',counter: 256
        }).then( data => {
            console.log(data);
        }).catch(e => {
            console.log(e);
        })
    }

    const getFreeScinseSubscribe = async () => {
        if (user.sub) return setSnackbar(
            <Snackbar
                onClose={() => setSnackbar(null)}
                before={<Avatar size={24} style={{ background: 'green' }}>
                <Icon20CheckCircleFillGreen fill="green" width={24} height={24} />
                </Avatar>}>Вы уже получили подарок!</Snackbar>
        )

        bridge.send("VKWebAppJoinGroup", {"group_id": 187158682}).then(async data => {
            if (data.result === true) {
                let req = await request('get_free_science_subscribe', 'POST', {
                    url: document.location.search,
                })
                if (req.error) {
                    setSnackbar(
                        <Snackbar
                            onClose={() => setSnackbar(null)}
                            before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
                            <Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
                            </Avatar>}>{req.error}</Snackbar>
                    )
                } else {
                    setSnackbar(
                        <Snackbar
                            onClose={() => setSnackbar(null)}
                            before={<Avatar size={24} style={{ background: 'green' }}>
                            <Icon20CheckCircleFillGreen fill="#fff" width={24} height={24} />
                            </Avatar>}>Вы получили 20 подсказок!</Snackbar>
                    )
                    setUser(req.user)
                }
            }
        }).catch(e => {
            console.log(e);
            setSnackbar(
                <Snackbar
                    onClose={() => setSnackbar(null)}
                    before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
                    <Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
                    </Avatar>}>{'Ошибка, вы не подписались на сообщество!'}</Snackbar>
                )
        })
    }

    return (
        <Panel id={id}>
        <PanelHeader 
            left={
                <PanelHeaderButton
                    onClick={() => {
                      if (user.vibration) {
                        bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                      }
                        go({currentTarget: {dataset: {to: 'home'}}})
                    }}>
    
                    <Icon28ChevronBack fill="white" />
                    </PanelHeaderButton>
                }>
            Магазин
        </PanelHeader>
        <div className={Class}>
        <div className="statistic-menu">
        <div className="statistic-menu-header">бесплатные подсказки</div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
                getFreeScinse()
        }}>
            <Icon24VideoOutline width={30} height={30} />
            <div className="statistic-menu-item-state">Смотреть рекламу (+2)</div>
        </div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
                getFreeScinseSubscribe()
        }}>
            {user.sub ? <Icon24UserAdded width={30} height={30} /> : <Icon16UserAdd width={30} height={30} />}
            <div className="statistic-menu-item-state">Подписаться на группу (+20)</div>
        </div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
                getFreeScinsePost()
        }}>
            <Icon28ShareExternal width={30} height={30} />
            <div className="statistic-menu-item-state">Подписаться записью (+20)</div>
        </div>

         {1 === 2 ? <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
                getFreeScinseMenu()
        }}>
            {user.sub ? <Icon20AddCircle width={30} height={30} /> : <Icon16UserAdd width={30} height={30} />}
            <div className="statistic-menu-item-state">Добавить в меню(+20)</div>
        </div> : null}

        <div className="statistic-menu-header">Доступно для покупки</div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
            order('item1', 3)
        }}>
            <Icon24ShoppingCartOutline width={30} height={30} />
            <div className="statistic-menu-item-state">29 подсказок за 3 голоса</div>
        </div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
            order('item2', 3)
        }}>
            <Icon24ShoppingCartOutline width={30} height={30} />
            <div className="statistic-menu-item-state">59 подсказок за 6 голосов</div>
        </div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
            order('item3', 3)
        }}>
            <Icon24ShoppingCartOutline width={30} height={30} />
            <div className="statistic-menu-item-state">149 подсказок за 12 голосов</div>
        </div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
            order('item4', 3)
        }}>
            <Icon24ShoppingCartOutline width={30} height={30} />
            <div className="statistic-menu-item-state">309 подсказок за 24 голосов</div>
        </div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
            order('item5', 3)
        }}>
            <Icon24ShoppingCartOutline width={30} height={30} />
            <div className="statistic-menu-item-state">649 подсказок за 44 голосов</div>
        </div>

        <div className="statistic-menu-item" onClick={() => {
            if (user.vibration) {
                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }
            order('item6', 3)
        }}>
            <Icon24ShoppingCartOutline width={30} height={30} />
            <div className="statistic-menu-item-state">999 подсказок за 55 голосов</div>
        </div>
        <div className='footer'>После любой покупки реклама в игре будет отключена для вас!</div>
        </div>
        {snackbar}
        </div>
        </Panel>
    )
}

export default Shop