import React from "react"
import { Icon16StarCircleFillYellow } from '@vkontakte/icons';
import { Icon20LightbulbCircleFillYellow } from '@vkontakte/icons';

const HeaderInfo = ({user}) => {
    return (
        <div>
		<div className='main-menu-top-item' style={{ left: 5}}>
      			<div style={{ marginRight: 5}}>{user?.progress.length || 0}</div><Icon16StarCircleFillYellow width={20} height={20}/>
		</div>
		<div className='main-menu-top-item'><div style={{ marginRight: 5}}>{user?.science || 0}</div><Icon20LightbulbCircleFillYellow /></div>
        </div>
    )

}

export default HeaderInfo