import React, { useEffect, useState } from "react";
import bridge from '@vkontakte/vk-bridge';
import { Panel, PanelHeader, PanelHeaderButton, Alert, Snackbar, Avatar} from '@vkontakte/vkui';
import { Icon28ChevronBack } from '@vkontakte/icons';
import request from '../hooks/useHttp'
import './Statistic.css'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Icon20EducationOutline } from '@vkontakte/icons';
import { Icon24FavoriteOutline } from '@vkontakte/icons';
import { Icon24BrainOutline } from '@vkontakte/icons';
import { Icon16Flash } from '@vkontakte/icons';
import { Icon20FlashOutline } from '@vkontakte/icons';
import { Icon20RecentOutline } from '@vkontakte/icons';
import { Icon20RecentCircleFillYellow } from '@vkontakte/icons';
import { Icon24HistoryBackwardOutline } from '@vkontakte/icons';
import { Icon20CheckCircleFillGreen } from '@vkontakte/icons';
import { Icon16ErrorCircleOutline} from '@vkontakte/icons';

function msToTime(duration) {

    let days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 24)
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
    let minutes = Math.floor((duration / (1000 * 60)) % 60)

    return '- ' + days + " дней " + hours + " часов " + minutes + " Минут"
  }

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '80%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="white">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

const Statistic = ({id, go, user, Class, setPopout, setUser}) => {
    const [info, setInfo] = useState([])
    const [snackbar, setSnackbar] = useState(null)

    const Restart = async  () => {
        
        try {

            let req = await request('restart', 'POST', {
                url: document.location.search,
            })

            setUser(req.user)

            setSnackbar(<Snackbar
                onClose={() => setSnackbar(null)}
                before={<Avatar size={24} style={{ background: 'green' }}>
                <Icon20CheckCircleFillGreen fill="green" width={14} height={14} />
                </Avatar>}>Аккаунт успешно обнулен!</Snackbar>)

                setTimeout(() => {
                    bridge.send("VKWebAppClose", {"status": "success", "payload": {"name": "test"} });
                }, 2000)

        } catch (e) {
            setSnackbar(<Snackbar
                onClose={() => setSnackbar(null)}
                before={<Avatar size={24} style={{ background: 'var(--destructive)' }}>
                <Icon16ErrorCircleOutline fill="#fff" width={14} height={14} />
                </Avatar>}>Произшла ошибка!</Snackbar>)
        }
    }

    const restart = () => {
        setPopout(<Alert
            actionsLayout="horizontal"
            actions={[
              {
                title: 'Отмена',
                autoclose: true,
                mode: 'cancel',
              },
              {
                title: 'Обнулить',
                autoclose: true,
                mode: 'destructive',
                action: () => Restart()
              },
            ]}
            onClose={() => setPopout(null)}
          >
            <p>После обнуленя весь прогресс и подсказки будут потеряны! Приложение будет перезагружено.</p>
          </Alert>)
    }

    useEffect(() => {
        const start = async () => {
            const data = await request('get_info', 'POST', {
                url: document.location.search,
            })
            setInfo(data);
        }
       start()
    }, [])

    return (
        <Panel id={id}>
            <PanelHeader 
                left={
                    <PanelHeaderButton
                        onClick={() => {
                            if (user.vibration) {
                                bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                            }
                            go({currentTarget: {dataset: {to: 'home'}}})
                        }}>
        
                        <Icon28ChevronBack fill="white" />
                        </PanelHeaderButton>
                    }>
                Статистика
            </PanelHeader>
            <div className={Class}>
            <div className="statistic-menu">
                <div className="statistic-menu-header"> Прогресс</div>

                <div className="statistic-menu-item">
                    <Icon20EducationOutline width={30} height={30}/>
                    <div className="statistic-menu-item-state">
                        <div>{info?.user?.progress.length} Угаданые команды</div>
                        <LinearProgressWithLabel  style={{opacity: 0.5}} value={info?.user?.progress.length / info?.items * 100} />
                    </div>
                </div>

                <div className="statistic-menu-item">
                    <Icon24FavoriteOutline width={30} height={30}/>
                    <div className="statistic-menu-item-state">
                        <div>{info?.stars} Очки</div> 
                        <LinearProgressWithLabel  style={{opacity: 0.5}} value={info?.stars / info?.all_stars * 100} />
                    </div>
                </div>

                <div className="statistic-menu-item">
                    <Icon24BrainOutline width={30} height={30}/>
                    <div className="statistic-menu-item-state">
                        <div>{info?.user?.lvl} Уровень</div> 
                    </div>
                </div>

            </div>

            <div className="statistic-menu">
                <div className="statistic-menu-header"> Подсказки</div>
                <div className="statistic-menu-item">
                    <Icon16Flash width={30} height={30}/>
                    <div className="statistic-menu-item-state">
                        <div>{info?.user?.science} Подсказки</div> 
                    </div>
                </div>

                <div className="statistic-menu-item">
                    <Icon20FlashOutline width={30} height={30}/>
                    <div className="statistic-menu-item-state">
                        <div>{info?.user?.science_use} Использованые подсказки</div> 
                    </div>
                </div>
            </div>


            <div className="statistic-menu">
                <div className="statistic-menu-header"> Остальное</div>

                <div className="statistic-menu-item" onClick={restart}>
                    <Icon24HistoryBackwardOutline width={30} height={30}/>
                    <div className="statistic-menu-item-state">
                        <div>Сбросить игру</div> 
                    </div>
                </div>

                <div className="statistic-menu-item">
                    <Icon20RecentOutline width={30} height={30}/>
                    <div className="statistic-menu-item-state">
                        <div>Первый запуск {new Date(info?.user?.date).toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })} </div> 
                    </div>
                </div>

                <div className="statistic-menu-item">
                    <Icon20RecentCircleFillYellow width={30} height={30}/>
                    <div className="statistic-menu-item-state">
                        <div>Время использования {msToTime(new Date() - new Date(info?.user?.date))}</div> 
                    </div>
                </div>
            </div>
            </div>
        </Panel> 
    )
}

export default Statistic