import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol, Spinner } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';

import './App.css'

import fon from './img/fon.jpg'
import request from './hooks/useHttp'
import Games from './panels/Games';
import Start from './panels/Start';
import GameItem from './panels/GameItem';
import Statistic from './panels/Statistic';
import Settings from './panels/Settings';
import Top from './panels/Top';
import Shop from './panels/Shop';

const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setFetchedUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [isLoaded, setLoading] = useState(true)
	const [user, setUser] = useState(null)
	const [progress, setProgress] = useState(null)
	const [thisProgress, setThisProgress] = useState(null)
	const [item, setItem] = useState(null)
	const [string, setString] = useState(null)
	const [games, setGames] = useState(<Spinner size='large'/>)
	const [gameType, setGameType] = useState('commands')
	const [Class, setClass] = useState('default')

	useEffect(() => {
		const start = async () => {
			if (item) {
				const data = await request('get_game', 'POST', {
					url: document.location.search,
					id: item.id
				})

				if (!user.progress.find(x => x === item.id)) {
					let HELP = user.help.filter(x => x.id === item.id)
					if (HELP.length > 0) {
						HELP.map(x => {
							if (x.type === 'excess') {
								data.words = x.result
							}
						})
					}
				}

				setString(data)
			}
		}
		start()

		bridge.send('VKWebAppGetLaunchParams').then(data => {
            if (data.vk_platform === "desktop_web") {
                setClass('web-scroll')
            }
        }).catch(e => {
            console.log(e)
        });
	}, [item, user])

	useEffect(() => {
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setFetchedUser(user);
			setPopout(null);

			const data = await request('get_user', 'POST', {
				url: document.location.search,
			})

			const games = await request('get_games', 'POST', {
				url: document.location.search,
			})
			
			setProgress(games.progress)
			setUser(data.user)
		}
		fetchData();
	}, []);

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

	if (isLoaded) {
		return (
		  <div style={{ height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
			<div style={{ display: 'none'}}>
			  <img src={fon} alt='0' onLoad={() => setLoading(false)}/>
			</div>
			<ScreenSpinner />
		  </div>
		)
	  }

	return (
		<ConfigProvider scheme='space_gray'>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={popout}>
						<SplitCol>
							<View activePanel={activePanel}>
								<Home id='home' 
								Class={Class}
								fetchedUser={fetchedUser} 
								go={go} 
								user={user} 
								setGameType={setGameType}
								setUser={setUser}/>
								<Games id='games' 
								Class={Class}
									fetchedUser={fetchedUser} 
									go={go} user={user} 
									progress={progress} 
									setThisProgress={setThisProgress}
									games={games} 
									setGames={setGames} 
									gameType={gameType}/>
								<Start id='start' 
								Class={Class}
									fetchedUser={fetchedUser} 
									go={go} user={user} 
									thisProgress={thisProgress} 
									setItem={setItem} 
									setUser={setUser}
									setProgress={setProgress}
									setThisProgress={setThisProgress}/>
								<GameItem id='game' 
								Class={Class}
									fetchedUser={fetchedUser} 
									go={go} user={user} 
									progress={progress} 
									item={item} 
									string={string} 
									setUser={setUser} 
									setProgress={setProgress}
									thisProgress={thisProgress}
									setPopout={setPopout}
									setThisProgress={setThisProgress}/>
								<Statistic id="statistic" go={go} 
									fetchedUser={fetchedUser} user={user} Class={Class} 
									setPopout={setPopout} setUser={setUser}/>
								<Settings id="settings" go={go} 
									fetchedUser={fetchedUser} 
									user={user} 
									setPopout={setPopout}
									setUser={setUser} Class={Class}/>
								<Top id="top" go={go} 
									user={user} Class={Class}/>
								<Shop id="shop" go={go} 
									user={user} setUser={setUser} Class={Class}/>
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
